import Joi from "joi";

export const create = Joi.object({
    sn: Joi.string()
        .min(3)
        .max(255)
        .trim()
        .required(),
    info: Joi.string()
        .max(255)
        .trim()
        .optional()
        .allow(null, "")
});

export const update = Joi.object({
    id: Joi.string()
        .regex(/^[0-9a-fA-F]{24}$/)
        .required(),
    name: Joi.string()
        .min(4)
        .max(255)
        .trim()
        .required(),
    info: Joi.string()
        .max(255)
        .trim()
        .optional()
        .allow(null, ""),
    token: Joi.string(),
    sn: Joi.string()
        .trim()
        .required(),
    patient: Joi.string()
        .allow(null, ""),
    enabled: Joi.boolean()
        .optional(),
    calibration_gyro_x: Joi.number()
        .optional()
        .allow(null, ""),
    calibration_gyro_y: Joi.number()
        .optional()
        .allow(null, ""),
    calibration_gyro_z: Joi.number()
        .optional()
        .allow(null, ""),
    tablet: Joi.object()
        .optional(),
    additionalDevices: Joi.string()
        .optional()
        .allow(null, ""),
    selected_entity: Joi.string()
        .regex(/^[0-9a-fA-F]{24}$/)
        .optional()
        .allow(null),
    entity: Joi.string()
        .regex(/^[0-9a-fA-F]{24}$/)
        .optional()
        .allow(null)
});

export const update_calibration = Joi.object({
    id: Joi.string()
        .regex(/^[0-9a-fA-F]{24}$/)
        .required(),
    entity: Joi.string()
        .regex(/^[0-9a-fA-F]{24}$/)
        .optional()
        .allow(null),
    calibration_gyro_x: Joi.number()
        .optional()
        .allow(null, ""),
    calibration_gyro_y: Joi.number()
        .optional()
        .allow(null, ""),
    calibration_gyro_z: Joi.number()
        .optional()
        .allow(null, "")
});

<template>
    <Page icon="mdi-tablet-cellphone" :title="$t('routes.devices')" :progress="progress">
        <ActionBar @filter="s => load(s)" :searchProgress="searchProgress">
            <v-btn @click="dialog = true" :open="dialog" @cancel="dialog = false" class="primary">
                <v-icon left>mdi-plus</v-icon> {{ $t("device.create.title") }}
            </v-btn>
        </ActionBar>
        
        <v-simple-table class="mt-3">
            <thead>
                <tr>
                    <th class="primary--text">
                        <SortHeader 
                            :text="$t('device.list.name')"
                            v-model="form.sort"
                            @change="load(form.filter)"
                            field="name"
                            type="alphanum"
                        />
                    </th>
                    <th class="primary--text">
                        {{ $t("device.list.sn") }}
                    </th>
                    <th class="primary--text">
                        <SortHeader 
                            :text="$t('device.list.patient')"
                            v-model="form.sort"
                            @change="load(form.filter)"
                            field="patient.name"
                            type="alphanum"
                        />
                    </th>
                    <th class="primary--text w-action">
                        {{ $t("device.list.edit") }}
                    </th>
                </tr>
            </thead>
            <tbody style="white-space: nowrap;">
                <tr v-for="(u, index) in devices" :key="index">
                    <td class="pt-3 pb-3">
                        <tr>
                            <span class="font-weight-bold">{{ u.name }}</span>
                        </tr>
                        <tr>
                            <span class="grey--text text--darken-2" >
                                <v-icon size="16">mdi-clipboard-text-outline</v-icon> {{ (u.info) ? u.info : $t("empty") }}
                            </span>
                        </tr>
                    </td>
                    <td>
                        <tr>
                            <span class="grey--text text--darken-2">
                                 <v-icon size="20">mdi-counter</v-icon> {{ u.sn }}
                            </span>
                        </tr>
                    </td>
                    <td>
                        <tr>
                            <span class="grey--text text--darken-2">
                                <v-icon size="20">mdi-account</v-icon> {{ (u.patient) ? u.patient.name : $t("empty") }}
                            </span>
                        </tr>
                    </td>
                    <td class="w-action">
                        <v-btn
                            fab
                            color="primary"
                            x-small
                            dark
                            depressed
                            :to="{ name: 'device', params: { id: u._id, routefrom: 'devices' } }"
                            ><v-icon>mdi-cog</v-icon>
                        </v-btn>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>

        <v-pagination
            class="mt-3"
            @input="load(form.filter)"
            v-if="pagination.total > 1"
            v-model="form.page"
            :length="pagination.total"
            :total-visible="7"
        />

        <DeviceCreate 
            :entity="selectedEntity()" 
            v-if="dialog" 
            :open="dialog" 
            @cancel="dialog = false" 
            @save="load(form.filter)" 
        />
    </Page>
</template>

<script>
import Page from "./components/Page.vue";
import ActionBar from "./components/ActionBar.vue";
import SortHeader from "./components/SortHeader.vue";
import DeviceCreate from "../views/dialogs/DeviceCreate.vue";

export default {
    components: {
        Page,
        ActionBar,
        DeviceCreate,
        SortHeader
    },

    async mounted() {
        this.load();
    },

    data: () => ({
        dialog: false,
        progress: true,
        searchProgress: false,
        devices: null,

        pagination: {
            total: 0,
            filter: null
        },

        form: {
            page: 1,
            limit: null,
            filter: null,
            sort: "-createdAt ", 
        }
    }),
    
    computed: {
        user() {
            return this.$store.state.api.user.data;
        }
    },

    methods: {
        async load(filter) {
            if (filter) this.form.filter = filter;
            else this.form.filter = null;

            if(this.pagination.filter != filter) this.form.page = 1;
            this.pagination.filter = filter;

            this.searchProgress = !this.progress;

            this.selectedEntity();

            const res = await this.$store.dispatch("api/device/all", this.form);

            if (res.task.count == 0) {
                this.progress = false;
                this.searchProgress = false;

                if(res.status == 200) {
                    this.devices = res.data;
                    this.pagination.total = res.pages;
                }
            }
        },

        selectedEntity(){
            let entity = this.$store.state.api.user.selectedEntity;
            if(!entity) return null;
            this.form.entity = entity._id;
            return entity._id;
        }
    }
};
</script>
